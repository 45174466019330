import axios from 'axios'
import { formatCurrency } from './numberFormatter'

const sendEmail = async (
    name,
    senderEmail,
    bankAccountNumber,
    recipient,
    totalAmount,
    dateToday,
    files,
    pdfData,
    emailType
) => {
    if (process.env.NODE_ENV !== 'production') recipient = 'js@newspark.nl'
    let attachments = []

    if (files)
        attachments = await Promise.all(
            Array.from(files).map(async (file) => ({
                name: file.name,
                contentType: file.type,
                contentBase64: await fileToBase64(file),
            }))
        )

    let emailSubject = ''
    let emailContent = ''
    let pdfFileName = ''

    if (emailType === 'declaration') {
        emailSubject = `Declaratie van ${name} - ${dateToday}`
        emailContent =
            'Beste ontvanger,\n\n' +
            'Hier een declaratie gemaakt met de decla-app, opgesteld door ' +
            name +
            '\n\n' +
            'Het totaalbedrag van deze declaratie is: € ' +
            formatCurrency(totalAmount) +
            (bankAccountNumber
                ? `\n\nHet bankrekeningnummer voor deze declaratie is: ${bankAccountNumber}`
                : '') +
            '\n\nVragen? neem contact op met ' +
            name +
            ' of met de bouwers van de decla-app.\n\nMvg,\n\nDecla-app'
        pdfFileName = `Declaratie van ${name} - ${dateToday}.pdf`
    } else if (emailType === 'kilometer') {
        emailSubject = `Kilometerregistratie van ${name} - ${dateToday}`
        emailContent =
            'Beste ontvanger,\n\n' +
            'Hier een kilometerregistratie gemaakt met de decla-app, opgesteld door ' +
            name +
            '\n\n' +
            'Het totaalbedrag van deze declaratie is: € ' +
            formatCurrency(totalAmount) +
            (bankAccountNumber
                ? `\n\nHet bankrekeningnummer voor deze declaratie is: ${bankAccountNumber}`
                : '') +
            '\n\nVragen? neem contact op met ' +
            name +
            ' of met de bouwers van de decla-app.\n\nMvg,\n\nDecla-app'
        pdfFileName = `Kilometerdeclaratie van ${name} - ${dateToday}.pdf`
    }

    attachments.push({
        name: pdfFileName,
        contentType: 'application/pdf',
        contentBase64: await fileToBase64(pdfData),
    })

    try {
        const response = await axios.post(
            'https://declaapp.nl/api/send-email.php',
            {
                to: recipient,
                cc: senderEmail,
                subject: emailSubject,
                content: emailContent,
                attachments: attachments,
            }
        )
        return response
    } catch (error) {
        console.error('There was an error sending the email', error)
        alert(error.message)
    }
}

const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',')[1])
        reader.onerror = (error) => reject(error)
    })
}

export default sendEmail
