import jspdf from 'jspdf'
import 'jspdf-autotable'
import { formatCurrency } from './numberFormatter'

const generatePDF = (
    name,
    declarationNumber,
    description,
    totalAmount,
    headers,
    data
) => {
    const doc = new jspdf({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
    })

    doc.setFontSize(10)
    doc.text(`Naam declarant: ${name}`, 15, 10)
    doc.text(`Declaratienummer: ${declarationNumber}`, 15, 20)
    doc.text(`Declaratie omschrijving: ${description}`, 15, 25)
    doc.text(
        `Totaalbedrag van deze declaratie: € ${formatCurrency(totalAmount)}`,
        15,
        30
    )

    doc.autoTable({
        head: headers,
        body: data,
        startY: 35,
        styles: { cellWidth: 'wrap', cellPadding: 1, fontSize: 8 },
        tableWidth: 'auto',
        setFontSize: 10,
    })

    const pdfData = doc.output('blob')
    return pdfData
}

const generateDeclarationPDF = (
    name,
    posts,
    categorySelectRefs,
    dateToday,
    totalAmount
) => {
    const sanitizedName = name.replace(/\s+/g, '').toLowerCase()
    const declarationNumber = `${sanitizedName}-${dateToday}`
    const description = `Declaratie van ${name} op ${dateToday.replace(/-/gm, ' ')}`

    const headers = [
        [
            'Datum',
            'Omschrijving',
            'Categoriecode',
            'Categorienaam',
            'Bedrag (excl. BTW)',
            'BTW bedrag',
            'Totaalbedrag',
        ],
    ]
    const data = posts.map((post, index) => {
        const categoryName =
            categorySelectRefs.current[index].current.options[
                categorySelectRefs.current[index].current.selectedIndex
            ].text
        const totalAmount =
            Number(post.totalAmountWithoutTaxes) + Number(post.taxAmount)
        return [
            dateToday,
            post.description,
            post.category,
            categoryName.substring(0, 20),
            '€ ' + formatCurrency(post.totalAmountWithoutTaxes),
            '€ ' + formatCurrency(post.taxAmount),
            '€ ' + formatCurrency(totalAmount),
        ]
    })

    return generatePDF(
        name,
        declarationNumber,
        description,
        totalAmount,
        headers,
        data,
        dateToday
    )
}

const generateKilometerPDF = (name, trips, dateToday, totalAmount) => {
    const sanitizedName = name.replace(/\s+/g, '').toLowerCase()
    const declarationNumber = `${sanitizedName}-${dateToday}`
    const description = `Kilometerregistratie van ${name} op ${dateToday.replace(/-/gm, ' ')}`

    const headers = [
        [
            'Datum',
            'Omschrijving rit',
            'Aantal km',
            'Kilometervergoeding',
            'Bedrag',
        ],
    ]
    const data = trips.map((trip) => {
        return [
            trip.date,
            trip.description,
            trip.kilometers.replace('.', ','),
            '€ 0,23',
            '€ ' + formatCurrency(trip.total),
        ]
    })

    const totalKilometers = trips.reduce(
        (sum, trip) => sum + parseFloat(trip.kilometers.replace(',', '.')),
        0
    )

    data.push([
        'Totaal',
        '',
        totalKilometers.toString().replace('.', ','),
        '',
        '€ ' + formatCurrency(totalAmount),
    ])

    return generatePDF(
        name,
        declarationNumber,
        description,
        totalAmount,
        headers,
        data,
        dateToday
    )
}

export { generateDeclarationPDF, generateKilometerPDF }
